import React, { Component } from 'react';
import { apiClient } from '../store/store';
import { FORM_FIELDS, BUTTON_SEND, HEADER_HEIGHT } from '../constants/constants';
import { trackingClick } from '../utils/react-ga';

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const errorsText = {
	name: 'Це поле повинно містити більше 2 символів',
	email: 'Введіть будь ласка коректний email',
	message: 'Це поле повинно містити більше 10 символів',
};

function formValid({ formErrors, fields }) {
	let valid = true;

	Object.values(formErrors)
		.forEach((value) => {
			if (value.length > 0) {
				valid = false;
			}
		});

	Object.entries(fields)
		.forEach(([key, value]) => {
			if (key === 'phoneNumber') return;
			const valueTrimed = value.trim();
			if (!valueTrimed) {
				valid = false;
			}
		});
	return valid;
}

class ContactForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {
				name: '',
				email: '',
				phoneNumber: '',
				message: '',
			},
			formErrors: {
				name: '',
				email: '',
				message: '',
			},
			isPosted: false,
		};

		this.formRef = React.createRef();
	}

		handleSubmit = (event) => {
			event.preventDefault();

			if (formValid(this.state)) {
				const options = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(this.state.fields),
				};
				apiClient.apiPost('/api/message', options);
				this.setState({
					isPosted: true,
				});
			}
		};

		handleInputChange = (event) => {
			event.preventDefault();
			const { name, value } = event.target;
			const { formErrors } = this.state;
			const fields = {};

			switch (name) {
			case 'name':
				formErrors.name = (value.length) < 3 || (!value.trim()) ? errorsText.name : '';
				fields.name = value;
				break;
			case 'email':
				formErrors.email = emailRegex.test(value) ? '' : errorsText.email;
				fields.email = value;
				break;
			case 'message':
				formErrors.message = value.length < 10 || (!value.trim()) ? errorsText.message : '';
				fields.message = value;
				break;
			case 'phoneNumber':
				fields.phoneNumber = value;
				break;
			default:
				break;
			}

			this.setState(prevState => ({
				formErrors,
				fields: { ...(Object.assign(prevState.fields, fields)) },
			}));
		};


		handleInputBlur = () => {
			const formElem = this.formRef.current;
			const formCoords = formElem.getBoundingClientRect();
			const formMargin = window.getComputedStyle(formElem)
				.getPropertyValue('margin-top');

			const shiftFromTop = HEADER_HEIGHT + parseFloat(formMargin);
			window.scrollBy(0, formCoords.top - shiftFromTop);
		};

		displayForm() {
			const { formErrors } = this.state;
			const {
				labelName, labelEmail, labelPhone, labelMessage,
			} = FORM_FIELDS;

			return (
				<form
					id="formContacts"
					method="post"
					className="formContacts"
					onSubmit={this.handleSubmit}
					// noValidate
					ref={this.formRef}
				>
					<label
						htmlFor="name"
						className="labelContacts"
					>
						{labelName}
						<input
							type="text"
							id="name"
							name="name"
							className={formErrors.name.length > 0 ? 'inputField inputError' : 'inputField'}
							onChange={this.handleInputChange}
							onBlur={this.handleInputBlur}
						/>
						{formErrors.name.length > 0 && (
							<span className="formError">{formErrors.name}</span>
						)}
					</label>
					<label
						htmlFor="email"
						className="labelContacts"
					>
						{labelEmail}
						<input
							type="text"
							id="email"
							name="email"
							className={formErrors.email.length > 0 ? 'inputField inputError' : 'inputField'}
							onChange={this.handleInputChange}
							onBlur={this.handleInputBlur}
						/>
						{formErrors.email.length > 0 && (
							<span className="formError">{formErrors.email}</span>
						)}
					</label>
					<label
						htmlFor="phoneNumber"
						className="labelContacts"
					>
						{labelPhone}
						<input
							type="tel"
							id="phoneNumber"
							name="phoneNumber"
							className="inputField"
							onChange={this.handleInputChange}
							onBlur={this.handleInputBlur}
						/>
					</label>
					<label
						htmlFor="message"
						className="labelContacts"
					>
						{labelMessage}
						<textarea
							id="message"
							cols="30"
							rows="10"
							name="message"
							className={formErrors.message.length > 0 ? 'contactMessage inputError' : 'contactMessage'}
							onChange={this.handleInputChange}
							onBlur={this.handleInputBlur}
						/>
						{formErrors.message.length > 0 && (
							<span className="formError">{formErrors.message}</span>
						)}
					</label>
					<button
						className="wrapper"
						type="submit"
						form="formContacts"
						disabled={!formValid(this.state)}
						onClick={() => trackingClick('Submit a form')}
					>
						{BUTTON_SEND}
						<span className="tooltip">Заповніть будь ласка форму</span>
					</button>
				</form>
			);
		}

		render() {
			const { isPosted } = this.state;
			return (
				<div>
					{isPosted
						? (
							<div className="successMessage">
								<div>
									<p>Дякуємо!</p>
									<p>Ваше повідомлення було&#160;відправлене.</p>
									<p>
																		Ми обов&#39;язково зв&#39;яжемося з&#160;вами
																		найближчим&#160;часом.
									</p>
								</div>

							</div>
						) : this.displayForm()}
				</div>
			);
		}
}

export default ContactForm;
