import React from 'react';
import PropTypes from 'prop-types';
import ArrowRight from './arrowRight';
import ArrowLeft from './arrowLeft';

const SliderButtons = (props) => {
	const { moveToNext } = props;
	return (
		<div className="buttonsContainer">
			<button
				className="prevButton"
				onClick={moveToNext('left')}
			>
				<ArrowLeft />
			</button>
			<button
				className="nextButton"
				onClick={moveToNext('right')}
			>
				<ArrowRight />
			</button>
		</div>
	);
};

SliderButtons.propTypes = {
	moveToNext: PropTypes.func,
};
export default SliderButtons;
