import React from 'react';
import PropTypes from 'prop-types';

class StickyContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fixedPosition: false,
		};
		this.btnContainerRef = React.createRef();
		this._isMounted = false;
	}

	componentDidMount() {
		this._isMounted = true;
		const initialTop = 550;

		window.addEventListener('scroll', () => {
			this.handleScroll(initialTop);
		});
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
		this._isMounted = false;
	}

		handleScroll = (initialTop) => {
			const { fixedPosition } = this.state;
			if (this._isMounted) {
				if (window.scrollY >= initialTop && fixedPosition === false) {
					this.setState({
						fixedPosition: true,
					});
				} else if (window.scrollY <= initialTop) {
					this.setState({
						fixedPosition: false,
					});
				}
			}
		};

		render() {
			const { fixedPosition } = this.state;
			const { children } = this.props;
			const fixed = fixedPosition ? 'fixed' : '';
			return (
				<React.Fragment>
					<div
						ref={this.btnContainerRef}
						className={`fixedContainer ${fixed}`}
					>
						{children}
					</div>
				</React.Fragment>
			);
		}
}

StickyContainer.propTypes = {
	children: PropTypes.object,
};

export default StickyContainer;
