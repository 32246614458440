import fetch from 'isomorphic-fetch';
const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

export const store = {
	fields: [],
	blogArticles: [],
	projects: [],
	achievements: [],
};

export const apiClient = {
	apiGet(url) {
		const finalUrl = isBrowser() ? url : `http://localhost:4000${url}`;
		return fetch(finalUrl)
			.then(result => result.json());
	},
	apiPost(url, options) {
		const finalUrl = isBrowser() ? url : `http://localhost:4000${url}`;
		return fetch(finalUrl, options)
			.then(result => result.json());
	},
};
