import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ProjectsListItem from './projects_list_item';
import {
	createMarkUp, getButtonsText,
} from '../utils/utils';
import { HEADER_HEIGHT, BUTTONS_DETAILS } from '../constants/constants';
import { trackingClick } from '../utils/react-ga';

const ProjectsSectionArch = ({ projects, title, bodyHTML }) => (
	<div
		id="architectural-design"
		style={{ paddingTop: HEADER_HEIGHT }}
		className="componentContainer windowHeight autoHeight backColor"
	>
		<div className="sectionInfo">
			<div className="columnFlexItem1 ">
				<h1 className="smallHeader">{title}</h1>
				<div
					className="resetQuill"
					dangerouslySetInnerHTML={createMarkUp(bodyHTML)}
				/>
				<div
					className="linkToPage"
				>
					<Link
						to="/projects/architecture"
						onClick={() => {
							trackingClick('Click to all projects architecture');
						}}
					>
						<span>+</span>
						{getButtonsText(BUTTONS_DETAILS, 'viewAll')}
					</Link>
				</div>
			</div>
			<ul className="galleryPreview">
				{projects && projects.map((project, index) => {
					if (project.isCoverImage && project.category === 'architecture') {
						return (
							<ProjectsListItem
								key={project.id}
								project={project}
								orderInList={index}
								galleryPreview
							/>
						);
					}
					return null;
				})}
			</ul>
		</div>
	</div>
);

ProjectsSectionArch.propTypes = {
	bodyHTML: PropTypes.string,
	title: PropTypes.string,
	projects: PropTypes.array,
};

export default withRouter(ProjectsSectionArch);
