import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import ProjectsListItem from './projects_list_item';
import { apiClient, store } from '../store/store';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../constants/constants';
import NavGalleryLinks from './navGalleryLinks';
import ButtonBack from './btnBack';

class Gallery extends Component {
	static fetchData() {
		return [
			apiClient.apiGet('/api/projects/')
				.then((data) => {
					store.projects = data;
					store.projects.sort((a, b) => (a.order > b.order ? 1 : -1));
				}),
		];
	}

	constructor(props) {
		super(props);
		this.state = {
			projects: store.projects || [],
		};
	}

	componentDidMount() {
		if (typeof window === 'object') {
			window.scrollTo(0, 0);
		}
		apiClient.apiGet('/api/projects/')
			.then((data) => {
				data.sort((a, b) => (a.orderInGallery > b.orderInGallery ? 1 : -1));
				this.setState({
					projects: data,
				});
			});
	}

	render() {
		const { match, history } = this.props;
		const selectedCategory = match.params.category;
		const { projects } = this.state;

		return (
			<div
				style={{
					paddingTop: HEADER_HEIGHT,
					paddingBottom: FOOTER_HEIGHT,
				}}
				className="componentContainerGallery"
			>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Архітектурні та інженерні проєкти компанії Архітєк</title>
					<meta
						name="description"
						content="Архітєк розробляє Робочі проєкти та кошторисну документацію нового будівництва,
						реконструкції, капітального ремонту, модернізації будівель та інженерних споруд.
						Одним з основних напрямків компанії є застосування енергозберігаючих технологій
						та відновлюваних джерел енергії (ВДЕ)."
					/>
					<meta
						property="og:title"
						content="Архітектурні та інженерні проєкти компанії Архітєк"
					/>
					<meta
						property="og:description"
						content="Архітєк розробляє Робочі проєкти та кошторисну документацію нового будівництва,
						реконструкції, капітального ремонту, модернізації будівель та інженерних споруд.
						Одним з основних напрямків компанії є застосування енергозберігаючих технологій
						та відновлюваних джерел енергії (ВДЕ)."
					/>
					<meta
						property="og:url"
						content="https://www.architek.in.ua/projects/all"
					/>
					<link
						rel="canonical"
						href="https://www.architek.in.ua/projects/all"
					/>
					<link
						href="https://fonts.googleapis.com/css?family=Roboto:400,500&amp;display=swap&amp;subset=cyrillic-ext"
						rel="stylesheet"
					/>
				</Helmet>
				<div className="galleryContainer">
					<NavGalleryLinks selectedCategory={selectedCategory} />
					<ul className="gallery maxWidthLimit">
						{projects.map((project, index) => {
							if (selectedCategory === 'all') {
								return (
									<ProjectsListItem
										key={project.id}
										project={project}
										orderInList={index}
									/>
								);
							}
							if (selectedCategory !== 'all' && project.category === selectedCategory) {
								return (
									<ProjectsListItem
										key={project.id}
										project={project}
										orderInList={index}
									/>
								);
							}
							return null;
						})
						}
						<ButtonBack goBack={history.goBack} />
					</ul>
				</div>
			</div>
		);
	}
}

Gallery.isGalleryRoute = true;

Gallery.propTypes = {
	match: PropTypes.object,
	history: PropTypes.object,
};

export default Gallery;
