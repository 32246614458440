import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import { createMarkUp, getButtonsText } from '../utils/utils';
import { BUTTONS_DETAILS, HEADER_HEIGHT } from '../constants/constants';
import { trackingClick } from '../utils/react-ga';

const OwnerInfo = ({ title, bodyHTML, photo, quote }) => (
	<div
		id="owner-info"
		style={{ paddingTop: HEADER_HEIGHT }}
		className="componentContainer  windowHeight autoHeight"
	>
		<div className="sectionInfo">
			<div className="columnFlexItem2">
				<div className="ownerPhotoOuterContainer">
					<div className="ownerPhotoContainer">
						{!!photo && (
							<img
								className="ownerImage"
								src={`/images/${photo}`}
								alt="Фото засновника компанії Архітєк"
							/>
						)}
					</div>
				</div>

			</div>
			<div className="columnFlexItem1">
				<p className="ownerName">{title}</p>
				<blockquote>{quote}</blockquote>
				<div
					dangerouslySetInnerHTML={createMarkUp(bodyHTML)}
					className="resetQuill"
				/>
				<Link
					to="/about-details"
					className="btnMoreInfo"
					role="button"
					tabIndex={0}
					onClick={() => {
						trackingClick('Click to more info button');
					}}
				>
					{getButtonsText(BUTTONS_DETAILS, 'learnMore')}
				</Link>
			</div>
		</div>
	</div>
);

OwnerInfo.propTypes = {
	title: PropTypes.string,
	bodyHTML: PropTypes.string,
	photo: PropTypes.string,
	quote: PropTypes.string,
};

export default OwnerInfo;
