import React from 'react';
import PropTypes from 'prop-types';
import {
	createMarkUp,
} from '../utils/utils';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../constants/constants';
import ContactForm from './contactForm';

const Contacts = ({ title, bodyHTML }) => (
	<div
		id="contacts"
		style={{ paddingTop: HEADER_HEIGHT, marginBottom: FOOTER_HEIGHT }}
		className="componentContainer windowHeight autoHeight"
	>
		<div className="sectionInfo">
			<div className="columnAddress">
				<h1 className="bigHeader">{title}</h1>
				<div className="resetQuill" dangerouslySetInnerHTML={createMarkUp(bodyHTML)} />
			</div>
			<div className="columnForm">
				<ContactForm />
			</div>
		</div>
	</div>
);

Contacts.propTypes = {
	title: PropTypes.string,
	bodyHTML: PropTypes.string,
};
export default Contacts;
