import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import AchievementsList from './achievementsList';
import Certificates from './certificates';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../constants/constants';
import ButtonBack from './btnBack';
import { apiClient, store } from '../store/store';

const AboutDetails = ({ history, location }) => {
	if (typeof window === 'object') {
		window.scrollTo(0, 0);
	}
	return (
		<div
			className="componentContainer aboutDetails"
			style={{ paddingTop: HEADER_HEIGHT, paddingBottom: FOOTER_HEIGHT }}
		>
			<Helmet>
				<title>Досвід та досягнення компанії</title>
				<meta
					name="description"
					content="Компанія Архітєк приймала участь у реалізації проєктів за підтримки
					міжнародних організацій GIZ та ПРООН. Являється власником сертифікатів.
					Має великий досвід з ﻿розробки робочої та кошторисної документації
					пам’яток Архітектури та культурної спадщини."
				/>
				<meta
					property="og:title"
					content="Досвід та досягнення компанії"
				/>
				<meta
					property="og:description"
					content="Компанія Архітєк приймала участь у реалізації проєктів за підтримки
					міжнародних організацій GIZ та ПРООН. Являється власником сертифікатів.
					Має великий досвід з ﻿розробки робочої та кошторисної документації
					пам’яток Архітектури та культурної спадщини."
				/>
				<meta
					property="og:url"
					content={`https://www.architek.in.ua${location.pathname}`}
				/>
			</Helmet>
			<AchievementsList />
			<Certificates />
			<div className="flexContainer">
				<div className="sectionAboutDetails">
					<div className="columnFlexItem2">
						<ButtonBack goBack={history.goBack} />
					</div>
				</div>
			</div>
		</div>
	);
};

AboutDetails.fetchData = () => [
	apiClient.apiGet('/api/achievements/')
		.then((data) => {
			store.achievements = data;
			store.achievements.sort((a, b) => (a.order > b.order ? 1 : -1));
		}),
];

AboutDetails.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};
export default AboutDetails;
