import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import { trackingClick } from '../utils/react-ga';
import { CATEGORIES_NAMES, CATEGORIES_TYPES } from '../constants/constants';

const NavGalleryLinks = (props) => {
	const { selectedCategory } = props;
	return (
		<nav className="galleryNavBar maxWidthLimit">
			<ul className="galleryNavItemsContainer ">
				{Object.keys(CATEGORIES_TYPES).map(type => (
					<li
						role="presentation"
						key={type}
					>
						<Link
							onClick={() => { trackingClick(`Click to  ${type} button in gallery navigation `); }}
							className={(selectedCategory === type) ? 'btnGalleryCategory selected' : 'btnGalleryCategory'}
							to={`/projects/${type}`}
						>
							{CATEGORIES_NAMES[type]}
						</Link>
					</li>
				))}
			</ul>
		</nav>
	);
};

NavGalleryLinks.propTypes = {
	selectedCategory: PropTypes.string,
};

export default NavGalleryLinks;
