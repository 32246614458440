import React from 'react';

const ArrowRight = () => (
	<div className="svgContainer">
		<svg
			className="carouselArrow"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			preserveAspectRatio="xMinYMin"
		>
			<path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
			<path
				fill="none"
				d="M0 0h24v24H0z"
			/>
		</svg>
	</div>
);

export default ArrowRight;
