import React, { Component } from 'react';
import { apiClient } from '../store/store';

class Certificates extends Component {
	constructor(props) {
		super(props);
		this.state = {
			certificates: [],
			sectionTitle: '',
		};
	}

	componentDidMount() {
		apiClient.apiGet('/api/certificates')
			.then((data) => {
				data.certificates.sort((a, b) => (a.order > b.order ? 1 : -1));
				this.setState({
					certificates: data.certificates,
					sectionTitle: data.sectionTitle,
				});
			});
	}

	render() {
		const { certificates, sectionTitle } = this.state;
		return (
			<div className="flexContainer">
				<div className="sectionAboutDetails">
					<div className="columnFlexItem1 columnMaxWidth">
						<h2 className="smallHeader subHeading">{sectionTitle}</h2>
						<ul>
							{certificates.map(certificate => (
								<li key={certificate.order}>
									<h3 className="certificateTitle">{certificate.title}</h3>
									{(certificate.image) ? (
										<div style={{
											width: '440px',
											maxWidth: '100%',
										}}
										>
											<figure className="certificateImg">
												<img
													src={certificate.image}
													alt="Сертифікат Architectural Construction Week in London, UK , 2008 р."
												/>
											</figure>
										</div>
									) : null}
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default Certificates;
