import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { Helmet } from 'react-helmet';
import Header from './header';
import Footer from './footer';
import { apiClient, store } from '../store/store';

const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

class App extends Component {
	static fetchData() {
		return [
			apiClient.apiGet('/api/projects/').then((data) => {
				store.projects = data;
				store.projects.sort((a, b) => (a.order > b.order ? 1 : -1));
			}),
			apiClient.apiGet('/api/sections/').then((data) => {
				data.sort((a, b) => (a.order > b.order ? 1 : -1));
				store.fields = data;
				store.fields.sort((a, b) => (a.order > b.order ? 1 : -1));
			})];
	}

	constructor(props) {
		super(props);
		this.state = {
			sections: [],
			projects: [],
		};
	}

	componentDidMount() {
		const { sections, projects } = this.state;
		if (store.fields.length && !sections.length) {
			this.setState({
				sections: store.fields,
			});
		}

		if (store.projects.length && !projects.length) {
			this.setState({
				projects: store.projects,
			});
		}
		if (!store.fields.length) {
			apiClient.apiGet('/api/sections/').then((data) => {
				data.sort((a, b) => (a.order > b.order ? 1 : -1));
				this.setState({
					sections: data,
				});
			});
		}
		if (!store.projects.length) {
			apiClient.apiGet('/api/projects/').then((data) => {
				this.setState({
					projects: data,
				});
			});
		}
	}

	render() {
		return (
			<div id="pageContainer">
				<Helmet>
					<meta charSet="utf-8" />
					<title>Архітектурно-проєктна та інженерна компанія Архітєк</title>
					<meta
						name="author"
						content="ТОВ «АРХІТЄК»"
					/>
					<meta
						name="description"
						content="Компанія Архітєк розробляє проектно-кошторисну документацію в сфері Архітектури та інжиніринга.
						Розробляє проєкти з застосуванням технологій енергозбереження,
						енергоефективності та відновлюваних джерел енергії (ВДЕ).
						Супроводжує процес проходження експертизи проєктів та кошторисної документації."
					/>
					<meta
						property="og:title"
						content="Архітектурно-проєктна та інженерна компанія Архітєк"
					/>
					<meta
						property="og:description"
						content="Компанія Архітєк розробляє проектно-кошторисну документацію в сфері Архітектури та інжиніринга.
						Розробляє проєкти з застосуванням технологій енергозбереження,
						енергоефективності та відновлюваних джерел енергії (ВДЕ).
						Супроводжує процес проходження експертизи проєктів та кошторисної документації."
					/>
					<meta
						property="og:image"
						content="https://www.architek.in.ua/mainBackground.jpg"
					/>
					<meta
						property="og:url"
						content="https://www.architek.in.ua"
					/>
					<link href="https://fonts.googleapis.com/css?family=Open+Sans:400|Roboto:500&amp;display=swap&amp;subset=cyrillic-ext" rel="stylesheet" />
				</Helmet>
				<Header sections={store.fields} />
				{renderRoutes(
					this.props.route
					&& this.props.route.routes, {
						...(isBrowser() ? this.state : {
							sections: store.fields,
							projects: store.projects,
						}),
					},
				)
				}
				<Footer />
			</div>
		);
	}
}

App.propTypes = {
	route: PropTypes.object,
};

export default withRouter(App);
