import React, { Component } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { apiClient, store } from '../store/store';
import BlogListItem from './blogListItem';
import ButtonBack from './btnBack';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../constants/constants';

class Blog extends Component {
	static fetchData() {
		return [
			apiClient.apiGet('/api/blog/')
				.then((data) => {
					store.blogArticles = data;
					store.blogArticles.sort((a, b) => (a.order > b.order ? 1 : -1));
				}),
		];
	}

	constructor(props) {
		super(props);
		this.state = {
			blogArticles: store.blogArticles || [],
		};
	}

	componentDidMount() {
		if (typeof window === 'object') {
			window.scrollTo(0, 0);
		}

		apiClient.apiGet('/api/blog/')
			.then((data) => {
				data.sort((a, b) => (a.order > b.order ? 1 : -1));
				this.setState({
					blogArticles: data,
				});
			});
	}

	render() {
		const { blogArticles } = this.state;
		const { route, history, location } = this.props;
		return (
			<div
				style={{ paddingTop: HEADER_HEIGHT, paddingBottom: FOOTER_HEIGHT }}
				className="componentContainer backColor"
			>
				<Helmet>
					<title>Архітектурні новини та статті</title>

					<meta
						name="description"
						content="Статті на тему будівництва та архітектури. Огляд цікавих новин у галузі проєктування"
					/>
					<meta
						property="og:title"
						content="Архітектурні новини та статті"
					/>
					<meta
						property="og:description"
						content="Статті на тему будівництва та архітектури. Огляд цікавих новин у галузі проєктування"
					/>
					<meta
						property="og:url"
						content={`https://www.architek.in.ua${location.pathname}`}
					/>
					<link
						href="https://fonts.googleapis.com/css?family=Roboto:400,500&amp;display=swap&amp;subset=cyrillic-ext"
						rel="stylesheet"
					/>
				</Helmet>
				<div className="gallery maxWidthLimit">
					<ul className="listItems">
						{blogArticles.map(article => (
							<BlogListItem
								key={article.id}
								title={article.title}
								article={article}
							/>
						))}
					</ul>
					<ButtonBack goBack={history.goBack} />
				</div>

				{renderRoutes(route.routes)}
			</div>
		);
	}
}

Blog.propTypes = {
	route: PropTypes.object,
	history: PropTypes.object,
	location: PropTypes.object,
};

export default Blog;
