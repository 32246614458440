import React from 'react';
import PropTypes from 'prop-types';

const HamburgerMenu = ({ isHamburgerOpen, onHamburgerClick }) => (
	<div
		onClick={() => {
			onHamburgerClick();
		}}
		className={isHamburgerOpen ? 'hamburger open' : 'hamburger'}
	>
		<div className="lineBurger" />
	</div>
);

HamburgerMenu.propTypes = {
	isHamburgerOpen: PropTypes.bool,
	onHamburgerClick: PropTypes.func,

};

export default HamburgerMenu;
