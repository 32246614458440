import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { getButtonsText } from '../utils/utils';
import { BUTTONS_DETAILS } from '../constants/constants';

const BlogListItem = ({ article }) => (
	<li className="columnBlog">
		<div className="blogCard small">
			<div className="blogCardImage">
				<img
					src={`/images/${article.thumbnailImage}`}
					alt="Acticle cover"
				/>
			</div>
			<div className="blogCardContent">
				<h3>{article.title}</h3>
			</div>
			<div className="blogcardAction">
				<Link to={`/blog/article/${article.id}`}>{getButtonsText(BUTTONS_DETAILS, 'read')}</Link>
			</div>
		</div>
	</li>
);

BlogListItem.propTypes = {
	article: PropTypes.object,
};
export default withRouter(BlogListItem);
