import React from 'react';
import PropTypes from 'prop-types';
import { createMarkUp } from '../utils/utils';

const AchievementsListItem = ({
	date, title, bodyHTML, orderInList,
}) => {
	const style = (orderInList % 2 !== 0);

	return (
		<li
			className="flexContainer"
			style={{
				background: (style) ? '#FAFAFA' : 'none',
				border: (style) ? '1px solid #f1f3f4' : 'none',
			}}
		>
			<div
				className="sectionAboutDetails"
			>
				<p className="achievementsDate smallHeader">{date}</p>
				<div className="columnFlexItem2 columnMinWidth columnMaxWidth">
					<h3 className="achievementsTitle">{title}</h3>
				</div>
				<div className="columnFlexItem2 columnMinWidth columnMaxWidth  indentLeftAbout">
					<div className="resetQuill" dangerouslySetInnerHTML={createMarkUp(bodyHTML)} />
				</div>
			</div>
		</li>
	);
};

AchievementsListItem.propTypes = {
	date: PropTypes.string,
	title: PropTypes.string,
	bodyHTML: PropTypes.string,
	orderInList: PropTypes.number,
};

export default AchievementsListItem;
