import React from 'react';
import PropTypes from 'prop-types';
import { orderOfComponents } from '../utils/orderOfComponents';

const SectionsMainPage = ({ sections, projects }) => {
	if ((projects && projects.length) && (sections && sections.length)) {
		return (
			<div className="centeredBlock">
				{sections.map(section => (
					<section key={section.order}>
						{orderOfComponents[section.order]({
							title: section.title,
							order: section.order,
							projects,
							bodyHTML: section.bodyHTML,
							hash: section.hash,
							photo: section.photo,
							quote: section.quote,
						})}
					</section>
				))}
			</div>
		);
	}
	return (
		<div style={{ width: '100%', display: 'flex', alignItems: 'center' }} />
	);
};
SectionsMainPage.propTypes = {
	sections: PropTypes.array,
	projects: PropTypes.array,
};

export default SectionsMainPage;
