import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';

class ProjectListItem extends Component {
	constructor(props) {
		super(props);
		this.state = { selectedProject: false };
	}

	toggleProjectSelection = bool => () => {
		this.setState({ selectedProject: bool });
	};

	render() {
		const {
			project: {
				thumbnailImage, category, title, subtitle, id,
			}, orderInList, galleryPreview,
		} = this.props;

		let choosenStyle;
		if (this.state.selectedProject && orderInList % 2 === 0) {
			choosenStyle = 'cardHoverBackground switchOn colorBlue';
		} else if (this.state.selectedProject) {
			choosenStyle = 'cardHoverBackground switchOn colorOrange ';
		} else {
			choosenStyle = 'cardHoverBackground ';
		}

		return (
			<li
				onTouchStart={this.toggleProjectSelection(true)}
				onTouchEnd={this.toggleProjectSelection(false)}
				onMouseEnter={this.toggleProjectSelection(true)}
				onMouseLeave={this.toggleProjectSelection(false)}
				className="card"
			>
				<Link to={`/projects/${category}/${id}`}>
					<img
						alt={title}
						className="cardImage"
						src={`/images/${thumbnailImage}`}
					/>
					<div className={choosenStyle} />
					<div
						className={this.state.selectedProject ? 'projectTitleLink switchOn' : 'projectTitleLink'}
					>
						<div>
							<div className="projectTitle">
								{title}
							</div>
							{!galleryPreview ? (<div className="projectSubtitle">{subtitle}</div>) : null}
						</div>
					</div>
				</Link>
			</li>
		);
	}
}

ProjectListItem.propTypes = {
	project: PropTypes.object,
	orderInList: PropTypes.number,
	galleryPreview: PropTypes.bool,
};

export default ProjectListItem;
