import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { createMarkUp } from '../utils/utils';
import { apiClient, store } from '../store/store';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../constants/constants';
import ButtonBack from './btnBack';


class BlogArticle extends Component {
	static fetchData() {
		return [
			apiClient.apiGet('/api/blog/')
				.then((data) => {
					store.blogArticles = data;
					store.blogArticles.sort((a, b) => (a.order > b.order ? 1 : -1));
				}),
		];
	}

	constructor(props) {
		super(props);
		this.state = {
			blogArticles: store.blogArticles || [],
		};
	}

	componentDidMount() {
		const { blogArticles } = this.state;

		if (typeof window === 'object') {
			window.scrollTo(0, 0);
		}

		if (store.blogArticles.length && !blogArticles.length) {
			this.setState({
				blogArticles,
			});
		}

		if (!store.blogArticles.length) {
			apiClient.apiGet('/api/blog/')
				.then((data) => {
					data.sort((a, b) => (a.order > b.order ? 1 : -1));
					this.setState({
						blogArticles: data,
					});
				});
		}
	}

	render() {
		const { match, history, location } = this.props;
		const { blogArticles } = this.state;
		const article = blogArticles.find(item => item.id === match.params.id);
		const bodyHTML = article && article.bodyHTML;
		return (
			<section
				style={{
					paddingTop: HEADER_HEIGHT,
					paddingBottom: FOOTER_HEIGHT,
				}}
				className="componentContainer"
			>
				<Helmet>
					<title>{article && article.title}</title>
					<meta
						name="description"
						content={article && article.metaDescription}
					/>
					<meta
						name="author"
						content={article && article.author}
					/>
					<meta
						property="og:title"
						content={article && article.title}
					/>
					<meta
						property="og:description"
						content={article && article.metaDescription}
					/>
					<meta
						property="og:image"
						content={`https://www.architek.in.ua/images/${article && article.thumbnailImage}`}
					/>
					<meta
						property="og:image:width"
						content="600"
					/>
					<meta
						property="og:image:height"
						content="600"
					/>
					<meta
						property="og:url"
						content={`https://www.architek.in.ua${location.pathname}`}
					/>
					<link href="https://fonts.googleapis.com/css?family=Open+Sans:400|Roboto:500,400&amp;display=swap&amp;subset=cyrillic-ext" rel="stylesheet" />
				</Helmet>
				<div className="sectionInfo">
					<article className="article">
						<h1>{article && article.title}</h1>
						<div
							className="resetQuill"
							dangerouslySetInnerHTML={createMarkUp(bodyHTML)}
						/>
						<ButtonBack goBack={history.goBack} />
					</article>
				</div>
			</section>
		);
	}
}

BlogArticle.isBlogArticleRoute = true;

BlogArticle.propTypes = {
	match: PropTypes.object,
	history: PropTypes.object,
	location: PropTypes.object,
};

export default withRouter(BlogArticle);
