import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import ProjectsListItem from './projects_list_item';
import {
	createMarkUp, getButtonsText,
} from '../utils/utils';
import { HEADER_HEIGHT, BUTTONS_DETAILS } from '../constants/constants';
import { trackingClick } from '../utils/react-ga';

const ProjectsSectionEng = ({ bodyHTML, title, projects }) => (
	<div
		id="engineering-design"
		style={{ paddingTop: HEADER_HEIGHT }}
		className="componentContainer windowHeight autoHeight"

	>
		<div className="sectionInfo">
			<div className="columnFlexItem1 orderChange ">
				<h1 className="smallHeader">{title}</h1>
				<div
					className="resetQuill"
					dangerouslySetInnerHTML={createMarkUp(bodyHTML)}
				/>
				<div className="linkToPage">
					<Link
						to="/projects/engineering"
						onClick={() => { trackingClick('Click to all projects engineering'); }}
					>
						<span>+</span>
						{getButtonsText(BUTTONS_DETAILS, 'viewAll')}
					</Link>
				</div>
			</div>
			<ul className="galleryPreview indentRight">
				{projects.map((project, index) => {
					if (project.isCoverImage && project.category === 'engineering') {
						return (
							<ProjectsListItem
								key={project.id}
								project={project}
								orderInList={index}
								galleryPreview
							/>
						);
					}
					return null;
				})}
			</ul>
		</div>
	</div>
);

ProjectsSectionEng.propTypes = {
	bodyHTML: PropTypes.string,
	title: PropTypes.string,
	projects: PropTypes.array,
};

export default ProjectsSectionEng;
