import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { createMarkUp } from '../utils/utils';
import CarouselMutObs from './carouselMutObs';
import { apiClient, store } from '../store/store';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../constants/constants';
import ButtonBack from './btnBack';
import StickyContainer from './stickyContainer';

class ProjectDetails extends Component {
	static fetchData() {
		return [
			apiClient.apiGet('/api/projects/')
				.then((data) => {
					store.projects = data;
					store.projects.sort((a, b) => (a.order > b.order ? 1 : -1));
				}),
		];
	}

	constructor(props) {
		super(props);
		this.state = {
			projects: store.projects || [],
		};
	}

	componentDidMount() {
		if (typeof window === 'object') {
			window.scrollTo(0, 0);
		}

		apiClient.apiGet('/api/projects/')
			.then((data) => {
				data.forEach((project) => {
					project.images.sort((a, b) => (a.orderInCarousel > b.orderInCarousel ? 1 : -1));
				});
				this.setState({
					projects: data,
				});
			});
	}

	render() {
		const { match, history, location } = this.props;
		const { projects } = this.state;
		const project = projects.find(item => item.id === match.params.id);
		const bodyHTML = project && project.bodyHTML;
		return (
			<div
				style={{
					paddingTop: HEADER_HEIGHT,
					paddingBottom: FOOTER_HEIGHT,
				}}
				className="componentContainer"
			>
				<Helmet>
					<title>{project && project.title}</title>
					<meta
						name="description"
						content={project && project.metaDescription}
					/>
					<meta
						property="og:title"
						content={project && project.title}
					/>
					<meta
						property="og:description"
						content={project && project.metaDescription}
					/>
					<meta
						property="og:image"
						content={`https://www.architek.in.ua/images/${project && project.thumbnailImage}`}
					/>
					<meta
						property="og:image:width"
						content="600"
					/>
					<meta
						property="og:image:height"
						content="600"
					/>
					<meta
						property="og:url"
						content={`https://www.architek.in.ua${location.pathname}`}
					/>
					<link
						href="https://fonts.googleapis.com/css?family=Open+Sans:400|Roboto:500,400&amp;display=swap&amp;subset=cyrillic-ext"
						rel="stylesheet"
					/>
				</Helmet>
				{project ? (
					<section className="projectDetailContainer">
						<div className="carouselContainerOuter">
							<CarouselMutObs project={project} />
							<StickyContainer>
								<ButtonBack goBack={history.goBack} />
							</StickyContainer>
						</div>
						<div className="projectDescription">
							<div>
								<h1>{project && project.title}</h1>
								<h2>{project && project.subtitle}</h2>
								<div
									className="resetQuill"
									dangerouslySetInnerHTML={createMarkUp(bodyHTML)}
								/>
							</div>
						</div>
					</section>
				) : null}
			</div>
		);
	}
}

ProjectDetails.isProjectRoute = true;

ProjectDetails.propTypes = {
	match: PropTypes.object,
	history: PropTypes.object,
	location: PropTypes.object,
};

export default withRouter(ProjectDetails);
