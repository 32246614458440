import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { trackingClick } from '../utils/react-ga';
import SliderButtons from './sliderButtons';

const getNextIndex = (direction, currentIndex, images) => {
	const lastIndex = images.length - 1;
	let nextIndex = (direction === 'left') ? currentIndex - 1 : currentIndex + 1;
	if (nextIndex < 0) {
		nextIndex = lastIndex;
	}
	if (nextIndex > lastIndex) {
		nextIndex = 0;
	}
	return nextIndex;
};

class CarouselMutObs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nextIndex: 0,
			currentIndex: 0,
			initClass: '',
			nextClass: '',
			prevClass: '',
			direction: '',
		};

		this.refToCarousel = React.createRef();
		this.isTransition = false;
		this.moveToNext = this.moveToNext.bind(this);
		this.makeMoveItems = this.makeMoveItems.bind(this);
		this.removeStyles = this.removeStyles.bind(this);
	}

	componentDidMount() {
		const elementToWatch = this.refToCarousel.current;
		const observer = new MutationObserver((mutations) => {
			const { direction } = this.state;
			for (const mutation of mutations) {
				if (mutation.target.className === `item ${direction}initClass`) {
					window.getComputedStyle(mutation.target).getPropertyValue('display');
					this.setState({
						nextClass: 'nextClass',
						prevClass: `${direction}prevClass`,
					});
				}
			}
		});

		observer.observe(elementToWatch, {
			attributes: true,
			childList: true,
			subtree: true,
		});
	}

	moveToNext = direction => () => {
		const { currentIndex } = this.state;
		const { project: { images } } = this.props;
		const nextIndex = getNextIndex(direction, currentIndex, images);
		this.makeMoveItems(nextIndex, direction);
		trackingClick('Click to slider button');
	};

	makeMoveItems(nextIndex, direction) {
		this.setState({
			initClass: `${direction}initClass`,
			direction,
			nextIndex,
		});
	}

	removeStyles() {
		const { nextIndex } = this.state;
		this.setState({
			initClass: '',
			nextClass: '',
			prevClass: '',
			currentIndex: nextIndex,
		});
	}

	render() {
		const { project: { images } } = this.props;
		const {
			currentIndex, initClass, nextIndex, nextClass, prevClass,
		} = this.state;
		let buttons;
		if (images.length > 1) {
			buttons = <SliderButtons moveToNext={this.moveToNext} />;
		} else {
			buttons = null;
		}

		return (
			<div className="carouselContainer">
				<div
					ref={this.refToCarousel}
					className="carouselCss"
				>
					{images.map((item, index) => {
						const elementClasses = classNames(
							'item',
							{
								[initClass]: nextIndex === index && initClass,
								[nextClass]: nextIndex === index && nextClass,
								[prevClass]: currentIndex === index && prevClass,
								active: currentIndex === index,
							},
						);
						return (
							<div
								key={item.imageSrc}
								className={elementClasses}
								style={{ display: currentIndex === index || (!!initClass && nextIndex === index) ? 'block' : 'none' }}
								onTransitionEnd={this.removeStyles}
							>
								<img className="imageCarousel" src={`/images/${item.imageSrc}`} alt={item.descForAlt} />
							</div>
						);
					})}
				</div>
				{buttons}
			</div>
		);
	}
}

CarouselMutObs.propTypes = {
	project: PropTypes.object,
};

export default CarouselMutObs;
