import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import NavLinksList from './navLinksLIst';
import HamburgerMenu from './hamburgerMenu';
import Logo from './logo';
import { HEADER_HEIGHT } from '../constants/constants';
import { trackingClick } from '../utils/react-ga';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isBackgroundVisible: false,
			isHamburgerOpen: false,
			isTransition: false,
		};

		this.removeTransition = this.removeTransition.bind(this);
		this.onHandleScroll = this.onHandleScroll.bind(this);
		this.onHamburgerClick = this.onHamburgerClick.bind(this);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.onHandleScroll);
		window.addEventListener('resize', this.closeHamburgerMenu);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onHandleScroll);
		window.removeEventListener('resize', this.closeHamburgerMenu);
	}

		closeHamburgerMenu = () => {
			const { isHamburgerOpen } = this.state;
			const { clientWidth } = document.body;
			const breakPoitWidth = 930;
			if ((clientWidth > breakPoitWidth && isHamburgerOpen) || isHamburgerOpen) {
				setTimeout(() => {
					this.setState({
						isHamburgerOpen: false,
					});
				}, 300);
			}
		};

		onHamburgerClick = () => {
			const { isHamburgerOpen } = this.state;
			this.setState({
				isHamburgerOpen: !isHamburgerOpen,
				isTransition: true,
			});
		};

		onHandleScroll = () => {
			const currentScrollPosition = window.pageYOffset;
			const visibility = currentScrollPosition > 0;
			this.setState({
				isBackgroundVisible: visibility,
			});
		};

		removeTransition = () => {
			this.setState({
				isTransition: false,
			});
		};

		render() {
			const { isBackgroundVisible, isTransition, isHamburgerOpen } = this.state;
			const { sections } = this.props;
			return (
				<header
					className="navBarWrapper"
					style={{
						height: HEADER_HEIGHT,
					}}
				>
					<nav
						style={{
							backgroundColor: (isBackgroundVisible) ? 'rgba(250, 250, 250, 0.95)' : 'transparent',
						}}
						className="navBar"
					>
						<div className="logoContainer">
							<Link
								className="logo"
								to="/"
								style={{ visibility: 'visible' }}
								onClick={() => {
									window.scrollTo({
										top: 0,
										left: 0,
										behavior: 'smooth',
									});
								}}
							>
								<span>Логотип компанії Архітек</span>
								<Logo />
							</Link>
						</div>
						<NavLinksList
							isTransition={isTransition}
							isHamburgerOpen={isHamburgerOpen}
							removeTransition={this.removeTransition}
							closeHamburgerMenu={this.closeHamburgerMenu}
							sections={sections}
						/>
						<HamburgerMenu
							onHamburgerClick={this.onHamburgerClick}
							isHamburgerOpen={isHamburgerOpen}
						/>
						<div
							role="button"
							tabIndex="0"
							className="phoneNumberContainer"
							onClick={() => {
								trackingClick('Click to phone number ');
							}}
							onKeyDown={() => {
								trackingClick('Press key on phone number');
							}}
						>
							<div className="phoneNumber">
								<a href="tel:+380506852199">+38 050 685 21 99</a>
							</div>
						</div>
					</nav>
				</header>
			);
		}
}

Header.propTypes = {
	sections: PropTypes.array,
};

export default Header;
