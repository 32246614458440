import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import { HEADER_NAVBAR_BUTTONS } from '../constants/constants';
import { trackingClick } from '../utils/react-ga';


const NavLinksList = ({
	isHamburgerOpen, isTransition, removeTransition, closeHamburgerMenu,
}) => (
	<ul
		className={isHamburgerOpen ? ' navBarItemsContainer sideBarActive' : 'navBarItemsContainer'}
		style={{ transition: isTransition ? 'all 0.5s' : null }}
		onTransitionEnd={removeTransition}
	>
		{HEADER_NAVBAR_BUTTONS.map((link) => {
			const linkNameToProjects = 'Проекти';
			if (link.isHeaderButton) {
				return (
					<li key={link.hash}>
						<Link
							onClick={() => {
								trackingClick(`Click to ${link.linkName}`);
								closeHamburgerMenu();
							}}
							to={(link.linkName === linkNameToProjects) ? '/projects/all' : link.hash}
							key={link.hash}
							smooth
						>
							{link.linkName}
						</Link>
					</li>
				);
			}
			return false;
		})}
	</ul>
);

NavLinksList.propTypes = {
	isHamburgerOpen: PropTypes.bool,
	isTransition: PropTypes.bool,
	removeTransition: PropTypes.func,
	closeHamburgerMenu: PropTypes.func,
};

export default NavLinksList;
