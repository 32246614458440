import React from 'react';


const NotFound = () => (
	<div className="centeredBlock">
		<div className="componentContainer">
			<div className="sectionInfo">
				<div className="columnFlexItem1">
					<h1 className="notFoundMessage">Такої сторінки не існує</h1>
					<p className="notFoundDescr">
						Схоже, це неправильна адреса,
						<br />
						або сторінка переїхала
					</p>
					<a
						className="btnMoreInfo"
						href="/"
					>
						Повернутися на головну
					</a>
				</div>
			</div>
		</div>
	</div>
);

NotFound.isNotFoundRoute = true;

export default NotFound;
