import React from 'react';
import PropTypes from 'prop-types';
import { BUTTON_BACK } from '../constants/constants';

const ButtonBack = ({ goBack }) => (
	<button type="button" className="btnBack" onClick={goBack}>
		<span className="iconContainer">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
			>
				<path d="M0 0h24v24H0z" fill="none" />
				<path
					d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"
				/>
			</svg>
			{BUTTON_BACK}
		</span>
	</button>
);

ButtonBack.propTypes = {
	goBack: PropTypes.func,
};

export default ButtonBack;
