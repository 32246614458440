import 'core-js';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { renderRoutes } from 'react-router-config';
import ReactGA from 'react-ga';
import { history } from './utils/history';
import { routes } from './utils/routes';
import { store } from './store/store';

const trackingId = GA_TRACKING_ID;
ReactGA.initialize(trackingId);

history.listen((location) => {
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

if (window.__INITIAL_STORE__) {
	store.projects = window.__INITIAL_STORE__.projects;
	store.fields = window.__INITIAL_STORE__.fields;
	store.blogArticles = window.__INITIAL_STORE__.blogArticles;
	store.achievements = window.__INITIAL_STORE__.achievements;
}

ReactDOM.render(
	<Router history={history}>{renderRoutes(routes)}</Router>,
	document.getElementById('root'),
);
