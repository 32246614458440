import React, { Component } from 'react';
import { apiClient, store } from '../store/store';
import AchievementsListItem from './achievementsListItem';
import { ABOUT_DETAILS_TITLES } from '../constants/constants';

class AchievementsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sectionTitle: ABOUT_DETAILS_TITLES.sectionTitle,
			sectionSubTitle: ABOUT_DETAILS_TITLES.sectionTitle,
			achievements: store.achievements || [],
		};
	}

	componentDidMount() {
		apiClient.apiGet('/api/achievements/')
			.then((data) => {
				data.sort((a, b) => (a.order > b.order ? 1 : -1));
				this.setState({
					achievements: data,
				});
			});
	}

	render() {
		const { sectionTitle, sectionSubTitle, achievements } = this.state;
		return (
			<div className="flexContainer">
				<div className="sectionAboutDetails">
					<div className="columnFlexItem2 columnMinWidth columnMaxWidth">
						<h1 className="bigHeader">{sectionTitle}</h1>
						<h2 className="smallHeader subHeading">{sectionSubTitle}</h2>
					</div>
				</div>
				<ul className="flexContainer">
					{achievements.map((achievement, index) => (
						<AchievementsListItem
							date={achievement.date}
							title={achievement.title}
							bodyHTML={achievement.bodyHTML}
							orderInList={index}
							key={achievement.order}
						/>
					))}
				</ul>
			</div>
		);
	}
}

export default AchievementsList;
