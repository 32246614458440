import React from 'react';
import PropTypes from 'prop-types';
import { createMarkUp } from '../utils/utils';
import { HEADER_HEIGHT } from '../constants/constants';

const AboutUs = ({ title, bodyHTML }) => (
	<div
		id="about"
		className="componentContainer backImg windowHeight"
		style={{ paddingTop: HEADER_HEIGHT }}
	>
		<div className="sectionInfo">
			<div className="columnFlexItem1">
				<h1 className="bigHeader">{title}</h1>
			</div>
			<div className="columnFlexItem2">
				<div
					dangerouslySetInnerHTML={createMarkUp(bodyHTML)}
					className=" resetQuill"
				/>
			</div>
		</div>
	</div>
);

AboutUs.propTypes = {
	title: PropTypes.string,
	bodyHTML: PropTypes.string,
};
export default AboutUs;
