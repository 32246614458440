import AboutUs from '../components/about_us';
import Services from '../components/services';
import ProjectSectionArch from '../components/projectsSectionArch';
import ProjectsSectionEng from '../components/projectsSectionEng';
import ProjectsSectionSust from '../components/projectsSectionSust';
import Contacts from '../components/contacts';
import OwnerInfo from '../components/ownerInfo';

export const orderOfComponents = {
	0: AboutUs,
	1: OwnerInfo,
	2: Services,
	3: ProjectSectionArch,
	4: ProjectsSectionEng,
	5: ProjectsSectionSust,
	6: Contacts,
};
