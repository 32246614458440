import React from 'react';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import { createMarkUp } from '../utils/utils';
import { trackingClick } from '../utils/react-ga';
import { HEADER_HEIGHT, HEADER_NAVBAR_BUTTONS } from '../constants/constants';

const Services = ({ title, bodyHTML }) => (
	<div
		id="services"
		style={{ paddingTop: HEADER_HEIGHT }}
		className="componentContainer  windowHeight autoHeight"
	>
		<div className="sectionInfo">
			<div className="columnFlexItem1">
				<h1 className="bigHeader" dangerouslySetInnerHTML={createMarkUp(title)} />
			</div>
			<div className="columnFlexItem2 resetQuill">
				<div dangerouslySetInnerHTML={createMarkUp(bodyHTML)} />
				<div className="btnContainer">
					{HEADER_NAVBAR_BUTTONS.filter(button => button.isServicesButton).map(button => (
						<HashLink
							smooth
							to={button.hash}
							className="buttonSection"
							key={button.hash}
							onClick={() => { trackingClick(`Click to ${button.linkName} anchor link main page`); }}
						>
							{button.linkName}
						</HashLink>
					))
					}
				</div>
			</div>
		</div>
	</div>
);

Services.propTypes = {
	title: PropTypes.string,
	bodyHTML: PropTypes.string,
};
export default Services;
