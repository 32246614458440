export const CATEGORIES_TYPES = {
	all: 'all',
	architecture: 'architecture',
	engineering: 'engineering',
	sustainable: 'sustainable',
};
export const CATEGORIES_NAMES = {
	all: 'Всі проекти',
	architecture: 'Архітектура',
	engineering: 'Інженерне проектування',
	sustainable: 'Енергозберігаючі технології',
};

export const HEADER_HEIGHT = 90;
export const FOOTER_HEIGHT = 80;

export const HEADER_NAVBAR_BUTTONS = [
	{
		hash: '/#owner-info',
		linkName: 'Про нас',
		isHeaderButton: true,
	},
	{
		hash: '/#services',
		linkName: 'Послуги',
		isHeaderButton: true,
	},
	{
		hash: '/#projects',
		linkName: 'Проекти',
		isHeaderButton: true,
	},

	{
		hash: '/#architectural-design',
		linkName: 'Архітектурні проекти',
		isServicesButton: true,

	},
	{
		hash: '/#engineering-design',
		linkName: 'Інженерні проекти',
		isServicesButton: true,
	},
	{
		hash: '/#energy-saving-technologies',
		linkName: 'Енергозберігаючі технології',
		isServicesButton: true,
	},
	{
		hash: '/#contacts',
		linkName: 'Контакти',
		isHeaderButton: true,
	},
];

export const FOOTER_NAVBAR_BUTTONS = {
	blog: 'Блог',
	facebook: 'Facebook',
};

export const FORM_FIELDS = {
	labelName: "Iм'я",
	labelEmail: 'Email',
	labelPhone: 'Телефон (необов’язково)',
	labelMessage: 'Повідомлення',
};
export const BUTTONS_DETAILS = {
	learnMore: 'Більше інформації',
	viewAll: 'Всі проекти',
	read: 'Читати далі',
};

export const ABOUT_DETAILS_TITLES = {
	sectionTitle: 'Hаш досвід',
	sectionSubTitle: 'Участь в проектах',
};

export const BUTTON_SEND = 'Відправити';
export const BUTTON_BACK = 'Повернутись назад';
