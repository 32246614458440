import SectionsMainPage from '../components/SectionsMainPage';
import Gallery from '../components/gallery';
import ProjectDetails from '../components/project_details';
import Blog from '../components/blog';
import BlogArticle from '../components/blogArticle';
import App from '../components/app';
import AboutDetails from '../components/about_details';
import NotFound from '../components/NotFound';

export const routes = [
	{
		component: App,
		path: '/',
		routes: [
			{
				path: '/',
				exact: true,
				component: SectionsMainPage,
			},
			{
				exact: true,
				path: '/projects/:category',
				component: Gallery,
			},
			{
				exact: true,
				path: '/projects/(all|engineering|sustainable|architecture)?/:id',
				component: ProjectDetails,
			},
			{
				exact: true,
				path: '/blog',
				component: Blog,
			},
			{
				exact: true,
				path: '/blog/article/:id',
				component: BlogArticle,
			},
			{
				exact: true,
				path: '/about-details',
				component: AboutDetails,
			},
			{
				path: '*',
				component: NotFound,
			},
		],
	},
];
