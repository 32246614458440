import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FOOTER_HEIGHT, FOOTER_NAVBAR_BUTTONS } from '../constants/constants';
import { trackingClick } from '../utils/react-ga';

const Footer = () => (
	<footer className="footer" style={{ height: FOOTER_HEIGHT }}>
		<div className="footerColumn leftSideFooter">+38&#160;050&#160;685&#160;21&#160;99</div>
		<ul className="footerContainer">
			<li className="footerColumn">
				<Link to="/blog">{FOOTER_NAVBAR_BUTTONS.blog}</Link>
			</li>
			<li className="footerColumn">
				<a
					onClick={() => { trackingClick('Click to facebook link'); }}
					href="https://www.facebook.com/arhitek77"
				>
					{FOOTER_NAVBAR_BUTTONS.facebook}
				</a>
			</li>
		</ul>
	</footer>
);

export default Footer;
